import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useContext } from "react";
import { isUserSeller } from "src/constants/user_constants";
import { GlobalContext } from "src/context/GlobalState";
import { deliveryBatchTypes } from "../constants/product.constant";

const DeliveryBatch = () => {
  const {
    dispatch,
    state: { productState, alertState }
  } = useContext(GlobalContext);
  const isSeller = isUserSeller(JSON.parse(localStorage.getItem("user")));
  return (
    <FormControl fullWidth margin="dense">
      <InputLabel sx={{ ml: -1.5, mt: 1 }} id="delivery-batch">
        Delivery Time
      </InputLabel>
      <Select
        disabled={isSeller || productState.productRecord?.skuTypeId === 1000}
        labelId="delivery-batch"
        value={productState.productRecord?.deliveryBatchId ?? ""}
        name="deliveryBatch"
        label="Delivery Batch"
        variant="standard"
        onChange={(e) => {
          if ((e.target.value === deliveryBatchTypes.digitalDelivery && productState?.productRecord?.skuTypeId !== 1000) || (e.target.value === deliveryBatchTypes.digitalDelivery && !productState?.productRecord?.additionalDetails)) {
            dispatch({ type: "alertDetail", payload: { ...alertState?.alertDetail, title: "Bad Configuration", heading: "Bad Configuration", body: "SKU type has to be of type Digital !", type: "warning" } });
            dispatch({ type: "showMessage", payload: true });
            return;
          }
          dispatch({ type: "productRecord", payload: { ...productState?.productRecord, deliveryBatchId: e.target.value } });
        }}
        InputLabelProps={{ shrink: true }}
      >
        {productState.deliveryBatchesDropDown.map((data) => (
          <MenuItem value={data.deliveryBatchId}>{data.name}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default DeliveryBatch;
